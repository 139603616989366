import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { PaginationContext } from '../../context/PaginationContext';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PaginationArrow = styled(FontAwesomeIcon)`
  color: var(--color-grey-lighter);
  font-size: 2rem;
  transition: color 0.1s ease-in;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.1s ease-in;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);

    ${PaginationArrow} {
      color: var(--color-grey-dark);
    }
  }

  &:active {
    transform: translateY(0);
  }
`;

const CurrentPageIndicator = styled.div`
  margin: 0 2rem;
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-grey-dark);
`;

const Pagination = () => {
  // Pagination context
  const { numOfPages, currentPage, changeCurrentPage } = useContext(
    PaginationContext
  );
  return (
    <FlexContainer>
      <ArrowWrapper onClick={() => changeCurrentPage(currentPage - 1)}>
        <PaginationArrow icon={faChevronLeft} />
      </ArrowWrapper>
      <CurrentPageIndicator>{currentPage}</CurrentPageIndicator>
      <ArrowWrapper onClick={() => changeCurrentPage(currentPage + 1)}>
        <PaginationArrow icon={faChevronRight} />
      </ArrowWrapper>
    </FlexContainer>
  );
};

export default Pagination;
