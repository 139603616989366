import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 2rem;
  background-color: var(--color-white);
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 0.5rem;
`;

const ImageWrapper = styled.div`
  margin-bottom: 3rem;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem 2rem;
`;

const Title = styled.h3`
  font-size: 2.5rem;
  color: var(--color-grey-dark);
  line-height: 120%;
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.2rem;
  font-size: 2rem;
`;

const PriceNumber = styled.div`
  font-size: inherit;
  font-weight: 400;
`;

const EuroSign = styled.div`
  margin-left: 0.1rem;
  font-size: inherit;
  font-weight: 400;
`;

const Description = styled.p`
  margin-top: 2.5rem;
  font-size: 1.7rem;
  color: var(--color-text);
  line-height: 120%;
`;

const Product = ({ data, lang }) => (
  <FlexContainer>
    <ImageWrapper>
      <Img
        style={{ borderRadius: '0.5rem 0.5rem 0 0' }}
        fluid={data.image.fluid}
        alt="product_image"
      />
    </ImageWrapper>
    <DetailsWrapper>
      <Title>{data.title[lang]}</Title>
      <Price>
        <PriceNumber>{data.price}</PriceNumber>
        <EuroSign>&euro;</EuroSign>
      </Price>
      <Description>{data.description[lang]}</Description>
    </DetailsWrapper>
  </FlexContainer>
);

export default Product;
