import React, { createContext, useState } from 'react';

const PaginationContext = createContext({
  numOfPages: 1,
  currentPage: 1,
  changeNumOfPages: () => {},
  changeCurrentPage: () => {},
});

const PaginationContextProvider = ({ children }) => {
  const [numOfPages, setNumOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // Change the number of existing page
  const changeNumOfPages = value => {
    setNumOfPages(value);
  };

  // Change the number of current rendered page
  const changeCurrentPage = currPage => {
    if (currPage < 1 || currPage > numOfPages) {
      return;
    } else {
      setCurrentPage(currPage);
    }
  };

  return (
    <PaginationContext.Provider
      value={{ numOfPages, changeNumOfPages, currentPage, changeCurrentPage }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export { PaginationContext, PaginationContextProvider };
