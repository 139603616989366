import React, { useContext, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import chunk from 'lodash/chunk';
import styled from 'styled-components';

import { LangContext } from '../../context/LangContext';
import { PaginationContext } from '../../context/PaginationContext';

import Product from '../../components/Products/Product';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 7rem;
  margin: 5rem 0em 3rem 0rem;
  width: 80%;

  @media screen and (max-width: 1340px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 9rem;
  }

  @media screen and (max-width: 860px) {
    grid-template-columns: repeat(1, 1fr);
    width: 60%;
  }

  @media screen and (max-width: 600px) {
    width: 70%;
  }

  @media screen and (max-width: 415px) {
    width: 80%;
  }
`;

const ProductsList = () => {
  const { lang } = useContext(LangContext);
  const { currentPage, changeNumOfPages } = useContext(PaginationContext);

  // Number of products to be shown in each page
  const PAGE_LIMIT = 6;

  const { allContentfulProduct: products } = useStaticQuery(graphql`
    query {
      allContentfulProduct {
        nodes {
          id
          title {
            el
            en
          }
          price
          description {
            el
            en
          }
          image {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);

  // Set number of total pages
  useEffect(() => {
    changeNumOfPages(Math.ceil(products.nodes.length / PAGE_LIMIT));
  });

  const productChunks = chunk(products.nodes, PAGE_LIMIT);

  return (
    <GridContainer>
      {productChunks[currentPage - 1].map(product => (
        <Product key={product.id} data={product} lang={lang} />
      ))}
    </GridContainer>
  );
};

export default ProductsList;
