import React, { useContext } from 'react';
import styled from 'styled-components';
import SEO from '../components/utils/seo';

import { LangContext } from '../context/LangContext';
import { PaginationContextProvider } from '../context/PaginationContext';

import Layout from '../components/Layout/Layout';
import ProductsList from '../components/Products/ProductsList';
import Pagination from '../components/Pagination/Pagination';

const H1 = styled.h1`
  margin-top: 7rem;
  font-size: 3.7rem;
`;

const Title = () => {
  const { lang } = useContext(LangContext);

  return <H1>{lang === 'el' ? 'Τα προϊόντα μας' : 'Our products'}</H1>;
};

const PaginationWrapper = styled.div`
  margin: 4rem 0;
`;

const Products = () => {
  return (
    <PaginationContextProvider>
      <Layout>
        <SEO title="Products" />
        <Title />
        <ProductsList />
        <PaginationWrapper>
          <Pagination />
        </PaginationWrapper>
      </Layout>
    </PaginationContextProvider>
  );
};

export default Products;
